import React, { useReducer, useEffect, useState } from 'react'
import './theme/index.css'
import { Router } from 'react-router-dom'
import SweetAlert from 'react-sweetalert2'
import './App.css'
import Routes from './routes'
import UserService from './services/UserService'
import history from './services/history'
import { userInfo } from './api/calls'
import SnackbarContextProvider from './components/SnackbarContext'

import {
  questionMiddleware,
  questionReducer,
  questionActions,
  initialState as questionInitialState,
} from './store/question'

import { Context } from './store/context'
import Hotjar from './helpers/Hotjar'

function App() {
  const { Provider } = Context
  const [currentUser, setCurrentUser] = useState(undefined)
  const [questionState = questionInitialState, questionDispatch] = useReducer(questionReducer)
  const [questActions, setQuestActions] = useState({})
  const [errorExaminee, setErrorExaminee] = useState(false)

  useEffect(() => {
    const find = async () => {
      try {
        const { data } = await userInfo(UserService.getUserId())
        setCurrentUser(data)
        localStorage.setItem('user_info', JSON.stringify({ ...data }))
        localStorage.setItem('codExaminee', data.codExaminee)
      } catch (error) {
        if (error.response.status === 404) {
          setErrorExaminee(true)
        }
      }
    }
    find()
  }, [])

  useEffect(() => {
    const enhancedQuestionDispatch = questionMiddleware(questionDispatch)
    setQuestActions(questionActions(enhancedQuestionDispatch))
  }, [])

  const handleAction = () => {
    //if (questionState.error.status) questActions.removeError(localStorage.getItem('codExaminee'))
    UserService.forceLogout()
  }

  return (
    <Provider
      value={{
        question: {
          actions: questActions,
          state: questionState,
        },
        user: {
          state: currentUser,
        },
      }}
    >
      <SweetAlert
        show={errorExaminee || questionState.error.status}
        title={
          questionState.error.status
            ? questionState.error.message
            : 'Estudante não encontrado, entre em contato com o administrador'
        }
        confirmButtonText="Ok"
        onConfirm={() => handleAction()}
      />
      <SnackbarContextProvider>
        <Hotjar />
        <Router history={history}>
          <Routes />
        </Router>
      </SnackbarContextProvider>
    </Provider>
  )
}

export default App
